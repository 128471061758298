// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  // apiUrl: "http://localhost:4000/s1-api",
  // socketUrl: "http://localhost:4000",
  // socketUrl2: "http://165.232.181.130:3000",

  apiUrl: "http://143.110.243.52:3000/s1-api",
  socketUrl: "http://143.110.243.52:3000",
  socketUrl2: "http://165.232.181.130:3000",
  firebaseConfig: {
    apiKey: "AIzaSyB3h7GdlPfXcmXUtFA-1V2N2fzHwJ2J8f8",
    authDomain: "bsf147-17150.firebaseapp.com",
    projectId: "bsf147-17150",
    storageBucket: "bsf147-17150.appspot.com",
    messagingSenderId: "1088113690676",
    appId: "1:1088113690676:web:faa0c6ef97fea427efe261",
    measurementId: "G-PSPBRF6PNW"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
