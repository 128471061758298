import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from './service/auth.service';
import { SocketService } from './service/socket.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'TrophyClient';
  isLogin = false;
  level: string;
  userId = '';
  isMobile: Boolean;
  timeStamp: string;
  private authListenerSubs: Subscription;
  private logoutSubs: Subscription;
  constructor(private authService: AuthService, private socketService: SocketService) { }

  ngOnInit() {
    this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    this.authService.autoAuthUser();
    this.isLogin = this.authService.getIsAuth();
    if (this.isLogin) {
      this.socketService.connect();
      this.firebaseTriggered();
    }
    this.authListenerSubs = this.authService
      .getAuthStatusListner()
      .subscribe((IsAuthenticated) => {
        this.isLogin = IsAuthenticated;
        if (this.isLogin) {
          this.socketService.connect();
          this.firebaseTriggered();
        }
        else {
          this.logoutSubs && this.logoutSubs.unsubscribe();
          this.socketService.disconnect();
          this.timeStamp = '';
        }
      });
  }

  firebaseTriggered() {
    this.userId = this.authService.getUserId();
    if (this.userId) {
      this.logoutSubs = this.socketService.getFirebaseT(this.userId).valueChanges().subscribe(data => {
        this.timeStamp = this.authService.getTimeStamp();
        if (data?.timeStamp != this.timeStamp && data?.timeStamp > this.timeStamp) {
          this.authService.dlogout();
        }
      });
    }
  }

  ngOnDestroy() {
    this.authListenerSubs.unsubscribe();
    this.logoutSubs && this.logoutSubs.unsubscribe();
  }
}