import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Subject, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

const BACKEND_URL = environment.apiUrl + '/user';

@Injectable({ providedIn: "root" })
export class UserService {
    private user: any;
    private statement: any[] = [];
    private jResult: any[] = [];
    private userUpdated = new Subject<{ result: any, status: number }>();
    private statementUpdated = new Subject<{ statement: any }>();
    private jResultUpdated = new Subject<{ jResult: any }>();

    constructor(private http: HttpClient, private router: Router) { }

    getUserById(userId: string) {
        this.http.post<{ message: string, result: any, status: number }>(BACKEND_URL + "/getClientById", { userId })
            .subscribe((userData) => {
                this.user = userData;
                this.userUpdated.next({
                    result: this.user.result,
                    status: userData.status
                });
            }, (err) => {
                this.userUpdated.next({
                    result: {},
                    status: 0
                });
            });
    }

    getUpdateUserListner() {
        return this.userUpdated.asObservable();
    }

    getClientStatement(start, end) {
        let query = `?start=${start}&end=${end}`;
        this.http.get<{ message: string, result: any, status: string }>(BACKEND_URL + '/getClientStatement' + query)
            .subscribe((data) => {
                this.statement = data.result;
                this.statementUpdated.next({
                    statement: [...this.statement]
                });
            });;
    }

    getUpdateClientStatementListner() {
        return this.statementUpdated.asObservable();
    }

    changeUserPassword(oldPassword: String, newPassword: String, rePassword: String) {
        const userData = { oldPassword, newPassword, rePassword };
        return this.http.post(BACKEND_URL + "/changeUserPassword", userData);
    }

    updateRateDiff(rateD: Number,) {
        const userData = { rateD };
        return this.http.post(BACKEND_URL + "/updateRateDiff", userData);
    }

    getJResult(start, end) {
        let query = `?start=${start}&end=${end}`;
        this.http.get<{ message: string, result: any, status: string }>(BACKEND_URL + '/getJResult' + query)
            .subscribe((data) => {
                this.jResult = data.result;
                this.jResultUpdated.next({
                    jResult: [...this.jResult]
                });
            });;
    }

    getUpdategetJResultListner() {
        return this.jResultUpdated.asObservable();
    }

    getsComm(body){
        return this.http.post(BACKEND_URL +'/getsComm', {userId:body})
    }
}